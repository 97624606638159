// @ts-nocheck
import {
  trigger,
  transition,
  style,
  query,
  group,
  animateChild,
  animate,
  keyframes,
  state,
} from '@angular/animations';

export const fader = trigger('routeAnimations', [
  // transition('*<=>*',[
  //     query(':enter, :leave',[
  //         style({
  //             position:'absolute',
  //             left:0,
  //             width:'100%',
  //             opacity:0,
  //             transform:'scale(0) translateY(100%) '
  //         })
  //     ],{optional:true}),
  //     query(':enter',[
  //         animate('600ms ease',
  //         style({opacity:1,transform:'scale(1) translateY(0)'}))
  //     ],{optional:true})
  // ])
]);

export const faderChild = trigger('routeAnimations', [
  transition('*<=>*', [
    query(
      ':enter, :leave',
      [
        style({
          // position:'absolute',
          left: 0,
          opacity: 0,
          'padding-top': '0px',
          'padding-right': '30px',
          'padding-left': '0px',
          'padding-bottom': '30px',
          width: '100%',
        }),
      ],
      { optional: true }
    ),
    query(
      ':enter',
      [
        animate(
          '1000ms ease',
          style({
            opacity: 1,
            'padding-top': '0px',
            'padding-right': '30px',
            'padding-left': '0px',
            'padding-bottom': '30px',
          })
        ),
      ],
      { optional: true }
    ),
  ]),
]);

export const slider = trigger('routeAnimations', [
  transition('*<=>isProjectRedactor', slideTo('left')),
  transition('*<=>isApiRedactor', slideTo('left')),
  transition('*<=>isRolePanelRedactor', slideTo('left')),
  transition('*<=>isUserRedactor', slideTo('left')),
]);

function slideTo(direction) {
  const optional = { optional: true };
  return [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          [direction]: 0,
          width: '100%',
        }),
      ],
      optional
    ),
    query(':enter', [style({ [direction]: '-100%' })]),
    group([
      query(':leave', [animate('600ms ease', style({ [direction]: '100%' }))]),
      query(':enter', [animate('600ms ease', style({ [direction]: '0%' }))]),
    ]),
  ];
}

export const fromBounce = trigger('routeAnimations', [
  transition('*<=>*', [
    query(':enter, :leave', [
      style({
        position: 'absolute',
        left: 0,
        width: '100%',
        opacity: 0,
        transform: 'scale(5) translateY(100%)',
        'padding-top': '0px',
        'padding-right': '30px',
        'padding-left': '0px',
        'padding-bottom': '30px',
      }),
    ]),
    query(':enter', [
      animate(
        '1000ms ease',
        style({ opacity: 1, transform: 'scale(1) translateY(0)' })
      ),
    ]),
  ]),
]);

export const ikbsSidebar = trigger('ikbsSidebar', [
  state(
    'open',
    style({
      height: '90vh',
      width: '400px',
      opacity: 1,
      backgroundColor: '#555555',
      padding: '15px',
    })
  ),
  state(
    'closed',
    style({
      backgroundColor: '#555555',
      height: '90vh',
      width: '10px',
    })
  ),
  transition('open => closed', [animate('1s')]),
  transition('closed => open', [animate('1s')]),
]);
